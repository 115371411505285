body {
    background-image: url(assets/images/background.jpg)
}

.home-banner {
    padding: 20px;
}

.puzzle {
    padding: 20px;
    border: 2px solid;
    margin-top: 5px;
    background-color: lightgray;
}

.card-body {
    padding: 5px!important;
}

@media only screen and (max-width: 768px) {
    .puzzle {
        flex: none!important;
        max-width: 100%!important;
        width: 100%!important;
    }
}

#puzzle-container {
    max-width: 70%;
}

.contacts {
    width: 50%!important;
}

.side-bar {
    background-color: lightgray;
    border: 5px solid;
    padding: 5px;
    margin-bottom: 5px;
}

.home-text {
    background-color: lightgray;
    border: 2px solid;
    padding: 5px;
}

ul {
    list-style-type:none;
}

.banner {
    width: 33%;
    border: 2px solid;
}

.card {
    border: none!important;
	height: 100%;
}

.side-header {
    color: white;
    font-size: 60px;
    -webkit-text-stroke: 2px black;
}

.card-img-top {
    padding: 5px;
}

.puzzle-buttons {
    padding: 10px;
}

.puzzle-row {
    padding-left: 0px;
}

.hide {
    display: none!important;
}

.btn {
    color: black!important;
    border: 2px solid!important;
    background-color: lightgray!important;
    box-shadow: 5px 10px 10px}